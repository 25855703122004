import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header4";
import Footer from "../layout/footer2";
import "../../index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { useState } from "react";
import ImtexForming from "../component/ImtexForming";
import Anuyalday from "../component/Anuyalday";
import Ifexexpo from "../component/Ifexexpo";
import Sportweek from "../component/Sportweek";
import Melting from "../component/Melting";
import OfficeOpen from "../component/OfficeOpen";
import Gramoday from "../component/Gramoday";
import WorldYoga from "../component/WorldYoga";
import AnuyalDay2019 from "../component/AnuyalDay2019";
import RepibliceDay from "../component/RepibliceDay";
import jsonData from "../JasonData/BloodDonationGallery.json";
import GBloodDonation from "../component/GBloodDonation";
import GCustomerVisit from "../component/GForgTech";

const Gallery = () => {
  const bg = require("../../images/banner/bnr1.jpg");
  const [isOpen, setIsOpen] = useState(false);

  

  const FG_image = [
    
  ];

  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Gallery</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/contactus">Home</Link>
                  </li>
                  <li>Gallery</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container p-b0" style={{ marginTop: "20px" }}>
          <div className="row">
            
            {/* Blood Donation */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GBloodDonation />
            </div>

            {/* Customer Visit */}
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GCustomerVisit />
            </div>

            {/* Forge Tech Event */}
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30 h-50">
              <div className="dlab-img-effect on rotate no-hover">
                <div
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <img
                    className="w-100"
                    src={require("../../../src/images/forgtech event/1 (6).webp")}
                  />
                </div>
                <div className="dlab-info-has p-a20 no-hover ">
                  <div className="dlab-post-title">
                    <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>
                      ForgeTech Event 2023
                    </h5>
                  </div>
                </div>
              </div>
              <SlideshowLightbox
                theme="lightbox"
                images={FG_image}
                showThumbnails={true}
                open={isOpen}
                lightboxIdentifier="lbox3"
                onClose={() => {
                  setIsOpen(false);
                }}
              ></SlideshowLightbox>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Anuyalday />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Ifexexpo />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Sportweek />
            </div>
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Melting />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <OfficeOpen />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Gramoday />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <WorldYoga />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <AnuyalDay2019 />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <RepibliceDay />
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
