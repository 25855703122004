import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header4'
import Footer from '../layout/footer2'

const bg = require('../../images/banner/bnr1.jpg')

class ContactUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
      } 

    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Contact Us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/contactus">Home</Link></li>
                                        <li>Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-full content-inner bg-white contact-style-1">
                        <div className="container">
                            <div className="row dzseth">
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link to="/#" className="icon-cell"><i className="ti-location-pin"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Address</h5>
                                            <p>330/1P, Hajipur, Near JK Lakshmi Cement, Motibhoyan Hajipur Road, Ta. Kalol, Dist. Gandhinagar 382721</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link to="/#" className="icon-cell"><i className="ti-email"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Email</h5>
                                            <p>mkt@plasmainduction.com <br/> sales@plasmainduction.com <br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link to="/#" className="icon-cell"><i className="ti-mobile"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Phone</h5>
                                            <p>+91 99042 25550 <br/> <br/><br/><br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link to="/#" className="icon-cell"><i className="ti-desktop"></i></Link></div>
                                        <div className="icon-content">
                                            <div className='row section-content box-sort-in  button-example m-b30'>
                                                <h5 className="dlab-tilte text-uppercase">Social</h5>
                                                <div className='col-md-3' style={{width:"40px", height:"30px"}}>
                                                    <a href ="https://www.facebook.com/Plasmainduction" target='_blank' className="site-button facebook m-r15 sharp-sm outline" style={{width:"30px", height:"30px"}}><i className="fa fa-facebook"></i></a>
                                                </div>
                                                <div className='col-md-3 mx-2' style={{width:"35px", height:"30px"}}>
                                                    <a href ="https://www.youtube.com/channel/UCVHO_8OEtSKL7M0j7Q4OBsQ" target='_blank' className="site-button youtube m-r15 sharp-sm outline" style={{width:"30px", height:"30px"}}><i className="fa fa-youtube-play"></i></a>
                                                </div>
                                                <div className='col-md-3 mx-1' style={{width:"35px", height:"30px"}}>
                                                    <a href ="https://www.linkedin.com/company/plasma-induction" target='_blank' className="site-button linkedin m-r15 sharp-sm outline" style={{width:"30px", height:"30px"}}><i className="fa fa-linkedin"></i></a>
                                                </div>
                                                <div className='col-md-3 mx-2' style={{width:"35px", height:"30px"}}>
                                                    <a href ="https://www.instagram.com/plasmainduction" target='_blank' className="site-button instagram m-r15 sharp-sm outline" style={{width:"30px", height:"30px"}}><i className="fa fa-instagram"></i></a>
                                                </div>
                                                <div className='col-md-3 mx-2' style={{width:"35px", height:"40px"}}>
                                                    <a href ="https://twitter.com/plasmainduction" target='_blank' className="site-button twitter m-r15 sharp-sm outline" style={{width:"30px", height:"30px"}}><i className="fa fa-twitter"></i></a>
                                                </div>
                                            </div>
                                            <div><br/><br/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 m-b30">
                                    <div className="p-a30 bg-gray clearfix radius-sm">
                                        <h3>Send Message Us</h3>
                                        <div className="dzFormMsg"></div>
                                        <form method="post" className="dzForm" action="script/contact.php">
                                            <input type="hidden" value="Contact" name="dzToDo" />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzName" type="text" required className="form-control" placeholder="Your Name"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group"> 
                                                            <input name="dzEmail" type="email" className="form-control" required  placeholder="Your Email Id" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="Phone"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzOther[Subject]" type="text" required className="form-control" placeholder="Subject"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <textarea name="dzMessage" rows="4" className="form-control" required placeholder="Your Message..."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                            <input className="form-control d-none"style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button name="submit" type="submit" value="Submit" className="site-button "> <span>Submit</span> </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 m-b30 d-flex">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.77237369759!2d72.42156061433717!3d23.178506516299926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c20d49b7a6901%3A0xe900daf9a6903467!2sPlasma+Induction+Pvt.+Ltd.!5e0!3m2!1sen!2sin!4v1533530254394" title="map" className="align-self-stretch radius-sm" style={{border:"0",width:"100%",minHeight:"100%"}} allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default ContactUs;