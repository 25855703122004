import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';

class InquiryPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      mobile: '',
      captcha: '',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleCaptchaChange = (value) => {
    this.setState({
      captcha: value,
    });
  };

  handleSubmit = () => {
    // Handle form submission here, e.g., send data to your server or perform validation
    console.log(this.state);
    this.props.onClose(); // Close the popup
  };

  render() {
    return (
      <Modal className='pb-100 pt-100' show={this.props.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="mobile">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="tel"
                name="mobile"
                value={this.state.mobile}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="captcha">
              <ReCAPTCHA
                sitekey="6LcVZR8oAAAAAL9GUNYVKYjSTEC601Exkfhi8KHZ"
                onChange={this.handleCaptchaChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            Submit Inquiry
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InquiryPopup;
