import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const GForgTech = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Blood_Donation = [
    {
      src: require("../../../src/images/forgtech event/1 (1).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (2).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (3).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (4).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (5).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (6).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (7).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (8).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (9).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (10).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (11).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (12).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (13).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (14).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (15).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (16).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (17).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (18).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (19).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (20).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (21).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (22).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (23).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (24).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (25).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (26).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (27).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (28).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (29).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (30).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (31).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (32).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (33).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (34).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (35).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (36).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (37).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (38).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (39).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (40).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (41).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (42).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (43).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (44).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (45).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (46).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (47).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (48).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (49).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (50).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (51).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (52).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (53).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (54).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (55).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (56).webp"),
    },
    {
      src: require("../../../src/images/forgtech event/1 (57).webp"),
    },
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/forgtech event/1 (1).webp")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>ForgeTech Event 2023</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={Blood_Donation}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default GForgTech